import React, { useEffect, useState } from 'react';
import { Select, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from '../../common/Buttons/CustomButton';
import ImageInputField from '../../common/Inputs/ImageInputField';
import SelectField from '../../common/Inputs/SelectField';
import { ChildLevel } from 'src/enums/Child';
import { SCHOOLS, registerChildSchema } from './metadata';
import AvatarSelection from 'src/components/common/Inputs/AvatarInputField';

const currentYear = new Date().getFullYear();

export interface RegisterFormData {
  image: File | null;
  firstName: string;
  lastName: string;
  birthYear: number;
  grade: ChildLevel;
  school: string;
  username: string;
  password: string;
}

interface RegisterChildFormProps {
  onSubmit: (data: RegisterFormData, cb: () => void) => void;
  loading: boolean
}

const defaultUsername = "john_doe";

const RegisterChildForm: React.FC<RegisterChildFormProps> = ({ onSubmit, loading }) => {
  const [suggestedUsername, setSuggestedUsername] = useState(defaultUsername);

  const form = useForm<RegisterFormData>({
    initialValues: {
      image: null,
      firstName: '',
      lastName: '',
      birthYear: new Date().getFullYear(),
      grade: ChildLevel.primary_3,
      school: '',
      username: '',
      password: '',
    },
    validate: yupResolver(registerChildSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const generateSuggestedUsername = () => {
    const firstName = form.getValues().firstName
    const lastName = form.getValues().lastName

    setSuggestedUsername(`${firstName.toLowerCase()}_${lastName.toLowerCase()}`);
  };

  useEffect(() => {
    generateSuggestedUsername();
  }, [form.getValues()]);

  const handleSubmit = (values: RegisterFormData) => {
    onSubmit(values, () => {
      form.reset();
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} className='h-full max-h-[500px] sm:max-h-[570px] p-0 overflow-hidden w-[95%] tablet:w-[80%] small-laptop:w-[60%] large-laptop:w-[60%] xl:w-[40%] mb-2' >
    <div className='h-[calc(100%-36px)] overflow-y-scroll w-full px-1' >
      <div className='relative flex flex-col justify-center' >
          <div className='flex flex-col items-center justify-between  gap-1 mb-4'>
   
          <AvatarSelection  
            onChange={(file) => form.setFieldValue('image', file)}
            error={form.errors.image as string | undefined}
          />
                 <Text className='text-lg font-light'>Select an Avatar</Text>
        </div>
          <div className='grid grid-cols-2 gap-4'>
          <InputField
            label="First Name"
            inputContainerClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12'
            className='text-sm sm:text-base'
            placeholder="Enter first name"
            {...form.getInputProps('firstName')}
          />
          <InputField
            label="Last Name"
               inputContainerClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12'
            className='text-sm sm:text-base '
            placeholder="Enter last name"
            {...form.getInputProps('lastName')}
              />
              </div>
          {/* </div> */}

          <div className="grid grid-cols-2 gap-4">
            <SelectField
              selectClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12 text-sm sm:text-base'
              label="Birth Year"
              placeholder="Select year of birth"
              data={Array.from({ length: 100 }, (_, i) => ({
                value: (currentYear - i).toString(),
                label: (currentYear - i).toString()
              }))}
              {...form.getInputProps('birthYear')}
            />
            <SelectField
              label="Grade"
                    selectClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12 text-sm sm:text-base'
   
              placeholder="Select grade"
              data={[
                {value: ChildLevel.primary_3, label: ChildLevel.primary_3}, 
                {value: ChildLevel.primary_4, label: ChildLevel.primary_4}, 
                {value: ChildLevel.primary_5, label: ChildLevel.primary_5}, 
                {value: ChildLevel.primary_6, label: ChildLevel.primary_6}, 
              ]}
              {...form.getInputProps('grade')}
            />
          </div>
          <SelectField
            label="School"
            selectClassName='text-sm sm:text-base my-4 h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12'
            placeholder="Select school"
            data={SCHOOLS}
            {...form.getInputProps('school')}
          />
          <InputField
            label="Username"
            inputContainerClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12'
            className='text-sm sm:text-base'
            placeholder="Enter username"
            {...form.getInputProps('username')}
          />
          <Text className='hidden sm:block' size="xs" color="gray" mt={-8} mb={8}>Please choose a User ID without any space. e.g. {suggestedUsername == "_" ? defaultUsername : suggestedUsername}</Text>
          <InputField
            label="Password"
            type="password"
            inputContainerClassName='h-9 tablet:h-10 small-laptop:h-10 large-laptop:h-12 xl:h-12'
            className='text-sm sm:text-base'
            placeholder="Enter password"
            {...form.getInputProps('password')}
            showPasswordToggle
          />
          <Text size="xs" color="gray" mt={-8}>Use at least one capital letter, one special character and a number in your password.</Text>
    

      </div>
    </div>
    <CustomButton loading={loading} size='sm' type="submit" disabled={!form.isValid()}>
        Register
    </CustomButton>

    </form>
  );
};

export default RegisterChildForm;