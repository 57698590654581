/**
 * These are configuration settings for the production environment.
 *
 * Do not include API secrets in this file or anywhere in your JS.
 *
 * https://reactnative.dev/docs/security#storing-sensitive-info
 */
export default {
  API_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/",
  AUTH_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/auth",
  USER_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users",
  PARENT_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/parent",
  CHAT_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/chat",
  DOCUMENTS_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/users/documents",
  ADMIN_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/admin",
  PAYMENTS_URL: "https://tutorbot-backend.blackhill-19e0ef70.eastus.azurecontainerapps.io/payment",
  STRIPE_URL:
    "pk_test_51NGEFwGYepGhH2d43eV6bDL7A64lMHz6v0blTBI2l5fiLPiidStAMuidNuOoE7ZT7Ax8nvfYfaQvhO5OXIE7MPU700JL7aJv0W",
  PUBSUB_URL: "wss://tutorbot-dev-webpubsub.webpubsub.azure.com/client/hubs/notification?access_token="
}
