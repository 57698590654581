// src/services/MainService/api.ts

import { ApisauceInstance, ApiResponse, create } from "apisauce";
import { ApiConfig } from "./api.types";
import Config from "src/config";
import { getStorageToken, setStorageToken, clearStorageToken } from "src/utils/auth.storage";
import { UserToken } from "src/interfaces";
import { ErrorKind, getGeneralApiProblem } from "./apiProblem";
import { ResponseKind, ResponseType } from "./api.types";
import { authService } from "../auth/auth.api";

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 100000,
};

export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;
  private refreshPromise: Promise<UserToken | null> | null = null;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
    });

    this.setupInterceptors();
  }

  private async refreshAccessToken(): Promise<UserToken | null> {
    try {
      const token = await getStorageToken();
      if (!token) throw new Error('No refresh token available');

      const refreshResponse = await authService.getRefreshToken(token.refresh_token);
      if (refreshResponse.kind === ResponseKind.OK && refreshResponse.data) {
        await setStorageToken(refreshResponse.data);
        return refreshResponse.data;
      }
      throw new Error('Token refresh failed');
    } catch (error) {
      await clearStorageToken();
      window.dispatchEvent(new Event('logout'));
      throw error;
    }
  }

  private async getRefreshTokenSingleton(): Promise<UserToken | null> {
    if (!this.refreshPromise) {
      this.refreshPromise = this.refreshAccessToken().finally(() => {
        this.refreshPromise = null;
      });
    }
    return this.refreshPromise;
  }

  private setupInterceptors(): void {
    this.apisauce.axiosInstance.interceptors.request.use(
      async (config: any) => {
        // Skip auth header for specific endpoints
        if (this.isAuthEndpoint(config.url)) {
          return config;
        }

        const token = await getStorageToken();
        if (!token) return config;

        const todaysDate = new Date();
        const tokenExpiry = new Date(token.expires_at);

        // If token is expired or about to expire (within 5 seconds), refresh it
        if (tokenExpiry.getTime() - todaysDate.getTime() < 5000) {
          try {
            const newToken = await this.getRefreshTokenSingleton();
            if (newToken) {
              config.headers.Authorization = `${newToken.token_type} ${newToken.access_token}`;
              return config;
            }
          } catch (error) {
            console.error('Token refresh error:', error);
            throw error;
          }
        }

        config.headers.Authorization = `${token.token_type} ${token.access_token}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    this.apisauce.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (this.isAuthEndpoint(originalRequest.url)) {
          return Promise.reject(error);
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newToken = await this.getRefreshTokenSingleton();
            if (newToken) {
              originalRequest.headers.Authorization = `${newToken.token_type} ${newToken.access_token}`;
              return this.apisauce.axiosInstance(originalRequest);
            }
            throw new Error('Token refresh failed');
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  private isAuthEndpoint(url?: string): boolean {
    if (!url) return false;
    return url.includes('/refresh') || 
           url.includes('/verify') || 
           url.includes('/token') || 
           url.includes('blob.core');
  }

  async getResponse<T>(
    response: ApiResponse<T>,
    hideError?: boolean
  ): Promise<ResponseType<T>> {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);

      if (problem.kind === ErrorKind.REJECTION_ERROR) {
        return {
          kind: ErrorKind.REJECTION_ERROR,
          errors: problem?.errors?.data?.detail,
        };
      } else if (problem.kind === ErrorKind.TOO_MANY_REQUESTS) {
        return {
          kind: ErrorKind.TOO_MANY_REQUESTS,
          errors: problem?.errors?.data?.detail,
        };
      } else {
        return {
          kind: ErrorKind.NOT_FOUND_ERROR,
          errors: "Oops something went wrong",
        };
      }
    }

    return { kind: ResponseKind.OK, data: response.data };
  }
}

export const api = new Api();