import React, { ReactNode } from 'react';
import { HeaderContainer } from '../common/Containers/HeaderContainer';
import { ActionIcon, Image } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface ParentHeaderProps {
  renderHeading: ReactNode;
  rightContent?: ReactNode;
  sideBarOpened: boolean;
  openSideBar: () => void;
  isMobile: boolean;
}

const ParentHeader = ({ renderHeading, rightContent, sideBarOpened, openSideBar, isMobile }: ParentHeaderProps) => {
  return (
    <header className="h-[55px] md:h-[70px] flex-shrink-0 border-b border-gray-200">
      <HeaderContainer>
        <div className='h-full w-full flex flex-row items-center justify-between md:justify-start'>
          {/* Desktop Layout */}
          <div className='hidden md:flex flex-grow justify-start'>
            {renderHeading}
          </div>

          {/* Mobile Layout */}
          <div className="md:hidden flex flex-row w-full items-center justify-between">
            {!sideBarOpened && (
              <>
                <ActionIcon
                  onClick={openSideBar}
                  className="text-gray-600 hover:text-gray-800"
                >
                  <Icon name="menu" />
                </ActionIcon>
                <div className="flex flex-row items-center">
                  <Image src={'/landing/logo.png'} alt="logo" className="max-w-20 max-h-20" />
                </div>
                {rightContent ? rightContent : <div className='flex-none w-10'/>}
              </>
            )}
          </div>
        </div>
      </HeaderContainer>
    </header>
  );
};

export default ParentHeader;