import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { store } from "../configureStore"
import { AdminAppSettings, AppSettings } from "src/interfaces/AppSettings"
import { getState } from "../types"
import { Dispatch } from "react"
import { authService } from "src/services/services/auth/auth.api"
import { ResponseKind } from "src/services/services/MainService"
import { ProcessedComplaint } from "src/interfaces/Complaints"

export interface appStore {
  appSettings: AppSettings
  adminConfig: AdminAppSettings
  selectedComplaint: ProcessedComplaint
}

const initialState: appStore = {
  appSettings: {
    greeting_message: '',
    parent_disclaimers: '',
    child_disclaimers: '',
    promo_code: '',
    promo_code_id: '',
    gdrive_enabled: false,
    logo_link: '/unwildered-logo-horizontalremovebgpreview-1@2x.png'
  },
  adminConfig: {
    llm_model_name: '',
    llm_prompt: "",
    llm_role: "",
    llm_temperature: 0,
    logo_link: "/unwildered-logo-horizontalremovebgpreview-1@2x.png",
    greeting_message: '',
    gdrive_enabled: false,
    updated_at: '',
    parent_disclaimers: '',
    child_disclaimers: '',
    promo_code: '',
    promo_code_id: '',
    },
    selectedComplaint: {
      id: 0,
      parent_email: '',
      feedback: '',
      child_username: '',
      flagged_message: '',
      previous_messages: [],
      created_at: '',
      status: ''
    }
}

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppSettings: (state, action: PayloadAction<AppSettings>) => {
      state.appSettings = action.payload
    },
    setGreetingsMessage: (state, action: PayloadAction<string>) => {
      state.appSettings.greeting_message = action.payload
    },
    setAdminSettings: (state, action: PayloadAction<AdminAppSettings>) => {
      state.adminConfig = action.payload
    },
    setComplaint: (state, action: PayloadAction<ProcessedComplaint>) => {
      state.selectedComplaint = {
        id: action.payload.id,
        parent_email: action.payload.parent_email,
        child_username: action.payload.child_username,
        feedback: action.payload.feedback,
        flagged_message: action.payload.flagged_message,
        previous_messages: action.payload.previous_messages,
        created_at: action.payload.created_at,
        status: action.payload.status
      }
    },
    setComplaintStatus: (state, action: PayloadAction<string>) => {
      state.selectedComplaint.status = action.payload
    }
  },
})

const {setAppSettings, setAdminSettings, setComplaint, setComplaintStatus} = app.actions


export const updateAppSettings = (appSettings: AppSettings) => ({
  type: setAppSettings.type,
  payload: appSettings,
})

export const updateSelectedComplaint = (selectedComplaint: ProcessedComplaint) => ({
  type: setComplaint.type,
  payload: selectedComplaint,
})

export const updateResolveComplaint = () => ({
  type: setComplaintStatus.type,
  payload: 'resolved',
})

export const handleUpdateAppSettings = () => {
  return async (dispatch: Dispatch<any>, state: getState) => {
    try{
      const response = await authService.getAppInfo()

      if(response.kind == ResponseKind.OK && response.data){
        const defaultPromoCode = JSON.parse(response.data.default_promo_code)
        const res = {
          ...response.data,
          promo_code: defaultPromoCode.promo_code,
          promo_code_id: defaultPromoCode.promo_code_id
        }
        dispatch(updateAppSettings(res))
      }else{
        throw "Error"
      }

    }catch{
        console.log("Could not update user to the redux");
    }
  }
}

export const updateAdminSettings = (appSettings: AdminAppSettings) => ({
  type: setAdminSettings.type,
  payload: appSettings,
})


export default app.reducer
