import { Image, Text } from "@mantine/core";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride"

interface ParentWalkthroughProps {
    startWalkthrough?: boolean
    onCloseWalkthrough: () => void
}
export const ParentWalkthrough = ({ startWalkthrough, onCloseWalkthrough }: ParentWalkthroughProps) => {
    const steps: Step[] = [
        {
          target: 'body',
          content: <div className="flex flex-col p-2 gap-2">
            <Image className="rounded-xl mb-4" src="/images/chatIntefracePlaceholder.png" alt="doodles" />
            <Text className="text-xl font-semibold">Welcome to MYAi</Text>
            <Text>Hi there! Let’s take a quick spin around MYAi. It’ll only take a minute to make you a pro in no time!</Text>
          </div>,
          disableBeacon: true,
          placement: 'center'
        },
        {
          target: '.home-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Home</Text>
          <Text className="text-left">This is your go-to spot to chat with MYAi."</Text>
          </div>,
        },
        {
          target: '.learner-chat-history-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Learner Chat History</Text>
          <Text className="text-left">Here’s where you can check out what each learners has been chatting about. Knowledge is power, right?</Text>
          </div>,
        },
        {
          target: '.settings-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Settings</Text>
          <Text className="text-left">Need to tweak something? Change settings, add learners, or reset passwords – it’s all right here. You're the boss!"</Text>
          </div>,
        },
        {
          target: '.recent-chat-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Recent Chats</Text>
          <Text className="text-left">Forgot where you left off? No worries, this is where you can revisit all your recent chats with MYAi!</Text>
          </div>,
        },
        {
          target: '.new-chat-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Start a new chat</Text>
          <Text className="text-left">Start a new chat with just a click.</Text>
          </div>,
          },
        {
          target: '.upload-files-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Upload Files</Text>
          <Text className="text-left">Got a picture of a question? Upload it here and start learning!</Text>
          </div>,
          },
    
        {
          target: '.search-prompt-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Search Prompts</Text>
          <Text className="text-left">Stuck on what to ask? Click these to kickstart your conversations with MYAi. No typing needed!</Text>
          </div>,
        },
    
      ];

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, origin, status, type } = data;
    
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          onCloseWalkthrough()
        }
      };

    return (
        <ReactJoyride
        steps={steps}
        run={startWalkthrough}
        continuous
        showProgress
        scrollToFirstStep
        showSkipButton
        hideBackButton
        hideCloseButton
        disableOverlayClose
        callback={handleJoyrideCallback}
        styles={{
          tooltip: {
            borderRadius: '20px',
            width: '400px'
          },
         tooltipContainer: {
      
       
         },
         options: {
            arrowColor: '#FFFFFF',
            primaryColor: '#29748F',
         },
         buttonNext: {
          backgroundColor: '#29748F',
          borderRadius: '10px',
          padding: '10px 20px'
         },
         buttonSkip: {
          border: '1px solid #29748F',
          padding: '10px 20px',
          borderRadius: '10px',
         }
        }}
      />
    )
}