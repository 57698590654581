export enum LookUpKeys {
    three_month= "Three Month",
    one_month = "One Month",
    six_month = "Six Month"
  }

export interface PriceItem {
    id: string,
    currency: string,
    lookup_key: LookUpKeys,
    product: {
      id: string,
      description: string,
      name: string,
      type: string,
    },
    recurring: {
      interval: string,
      interval_count: number,
      trial_period_days: null | number,
    },
    type: string,
    original_price: number,
    promo_price: number,
    discount_value: number,
    discount_type: "amount" | "percentage",
    unit_amount: number,
  }

export interface Prices{
    prices: PriceItem[]
  }

export interface UserPlan{
  has_active_subscription: boolean
  plan: string
  plan_detail: PriceItem
  subscription_id: string
  current_period_end: number
}

export interface GroupedPrices {
    [key: string]: PriceItem[];
  }

export const getPlanName = (plan?: string) => {
  if (!plan) return '';
  const planName = plan.split('_')[0];

  switch (planName) {
    case 'one':
      return 'Basic';
    case 'two':
      return 'Standard';
    case 'three':
      return 'Premium';
    default:
      return planName;
  }
}

export interface ProductWithPrices {
  products: {
    product_id: string;
    product_name: string;
    prices: {
      price_id: string;
      unit_amount: number;
      currency: string;
      lookup_key: string;
      billing_interval: string;
      active: boolean;
    }[];
  }[];
}

export interface CouponWithPromoCodes {
  coupons: {
    coupon_id: string;
    name: string;
    percent_off?: number;
    amount_off?: number;
    currency?: string;
    duration: string;
    promo_codes: {
      promo_code_id: string;
      code: string;
      max_redemptions?: number;
      times_redeemed: number;
      active: boolean;
      expires_at?: number;
    }[];
  }[];
}