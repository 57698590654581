import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { PaymentIntent } from "@stripe/stripe-js";
import { CheckoutPage } from '../Checkout/component';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import { Button, Image } from '@mantine/core';
import Icon from 'src/components/common/Icons/Icons/Icon';

export function CheckoutScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();
  
  if (!state?.price) {
    return <Navigate to="/parent" replace />;
  }

  const handlePaymentSuccess = (paymentIntent: PaymentIntent) => {
    navigate(parentRoutes.DASHBOARD);
  };

  return (
      <CheckoutPage 
      price={state.price} 
      onSuccess={handlePaymentSuccess}
    />
  );
}