import React, { useCallback, useEffect, useState } from 'react';
import { Card, Text, Button, Modal } from '@mantine/core';
import { TabSelector } from 'src/components/Toggles/TabSelectorToggle';
import DynamicLayout from 'src/components/Layout/DynamicLayout';
import { StyledContainer } from 'src/components/common/StyledComponents/Container';
import { StyledContainerInset } from 'src/components/common/StyledComponents/BackgroundContainer';
import { Notifications } from '@mantine/notifications';
import useResponsive from 'src/hooks/useResponsive';
import ParentChatSidebar from 'src/components/Sidebars/Parent/ParentSIdebarHome';
import ParentProfileSidebar from 'src/components/Sidebars/Parent/ParentSIdebarProfile';
import { DetailedProOptions } from 'src/components/Layout/ProPlansLayout';
import { useAppSelector } from 'src/redux/redux-store/hooks';
import { useNavigate } from 'react-router-dom';
import { usePayments } from 'src/hooks/usePayment';
import { paymentService } from 'src/services/services/payment/payment.api';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import { PriceItem } from 'src/interfaces/Payments';
import { parentService } from 'src/services/services/parent/parent.api';
import { ResponseKind } from 'src/services/services/MainService';
import { ChildrenData } from 'src/interfaces/Children';

const PricingScreen = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate()
  const [confirmUpgrade, setConfirmUpgrade] = useState(false)
  const [deletedChildren, setDeletedChildren] = useState<ChildrenData[]>([])
  const { isMobile, backgroundColorExterior, backgroundColor } = useResponsive();
  const {createSession, userPlan, groupedPrices, navigateToPortal, fetchPrices, prices} = usePayments()

  const handleCancellation = async () => {
    const url = await navigateToPortal();
    if (url) {
      window.location.href = url;
    }
  }

  const onUpgrade = async (plan: number) => {
      const response =  await parentService.checkPlanUpgrade(plan)
      if(response.kind === ResponseKind.OK && response.data){
       const children =  response.data.children
       if(children.length > 0){
        setConfirmUpgrade(true)
        setDeletedChildren(children)
       }else{
        handleCancellation()
       }
      }
  }
  

  const handleSubscribe = async (priceId: string) => {
    // const url = await paymentService.createCheckoutSession(priceId, "cus_QuLlTySvPBsqZz")
    // let url;
    // if(userPlan?.has_active_subscription){
    //   url = await navigateToPortal();
    // }else{
    //   url = await createSession(priceId)
    // }
    
    // if(url){
    //   window.open(url, '_blank');
    // }

    const priceItem = prices.find((price) => price.id === priceId)

    navigate(parentRoutes.CHECKOUT, {
      state: {
        price: priceItem,
      }
    })

  }

  const header = (
    <div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Profile</Text>
    </div>
  );

  useEffect(() => {
    if(groupedPrices){
      return
    }
    fetchPrices()
  }, [groupedPrices])

  const closeModal = () => {
    setConfirmUpgrade(false);
    setDeletedChildren([]);
  };

  const handleConfirmUpgrade = async () => {
    closeModal();
    await handleCancellation();
  };

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={ParentProfileSidebar}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          <div className='h-full w-full p-8'>
            <DetailedProOptions 
            hasActiveSubscription={userPlan?.has_active_subscription}
            preference={user.child_preference === 1 ? 'Solo Learner' : user.child_preference === 2 ? 'Dual Learners' : 'Trio Learners'}
            onCancel={onUpgrade} 
            prices={groupedPrices} 
            onSubscribe={handleSubscribe} 
            currentPlan={userPlan ? userPlan.plan_detail : null} 
            />
          </div>
          <Modal 
            opened={confirmUpgrade} 
            onClose={closeModal}
            centered
            title="Confirm Plan Change"
          >
            <div className="space-y-4">
              <Text>The following children will be removed from your account due to plan limitations:</Text>
              <div className="space-y-2">
                {deletedChildren.map((child) => (
                  <div key={child.id} className="p-3 border rounded">
                    <Text>{child.name}</Text>
                  </div>
                ))}
              </div>
              <div className="flex justify-end space-x-3 mt-4">
                <Button variant="outline" onClick={closeModal}>Cancel</Button>
                <Button color="red" onClick={handleConfirmUpgrade}>Confirm</Button>
              </div>
            </div>
          </Modal>
        </StyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default PricingScreen;