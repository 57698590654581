import React, { useEffect, useState } from "react";
import { loadStripe, Stripe, PaymentIntent } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { paymentService } from "src/services/services/payment/payment.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { PriceItem } from "src/interfaces/Payments";
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button, Image, LoadingOverlay } from "@mantine/core";
import { IconButton } from "src/components/common/Buttons/IconButton";
import { useNavigate, useNavigation } from "react-router-dom";
import Icon from "src/components/common/Icons/Icons/Icon";
import { useAppSelector } from "src/redux/redux-store/hooks";
import Config from "src/config";

// Define the type for the payment success callback
type CheckoutFormProps = {
  price: PriceItem;
  onSuccess: (paymentIntent: PaymentIntent) => void;
};

// const PromoCodeId = "promo_1QD5zUGYepGhH2d4ICQMBOnp"
// const PromoCode = "EARLYBIRD30"
// Initialize Stripe with your publishable key
const stripePromise = loadStripe(Config.STRIPE_URL);

interface PriceDetails {
  couponId: string;
  discountAmount: number;
  finalAmount: number;
}

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({ price, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const appSettings = useAppSelector((state) => state.app).appSettings;
  const defaultPromoCode = appSettings.promo_code
  const defaultPromoCodeId = appSettings.promo_code_id
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [priceDetails, setPriceDetails] = useState<PriceDetails | null>(null);
  const [promoCode, setPromoCode] = useState<string>('');
  // const [defaultPromoCode, setDefaultPromoCode] = useState({defaultPromoCodeId: PromoCodeId, defaultPromoCode: PromoCode});

  useEffect(() => {
    const applyDefaultPromo = async () => {
      try {
        const response = await paymentService.applyPromoCode(price.original_price, defaultPromoCode);
        if (response.kind === ResponseKind.OK && response.data) {
          setPriceDetails(response.data);
          setPromoCode(defaultPromoCode);
        }
      } catch (err) {
        console.error("Error applying default promo code:", err);
      } finally {
        setPageLoading(false);
      }
    };
    applyDefaultPromo();
  }, [price.original_price]);

  const [calculating, setCalculating] = useState(false);

  // Calculate the current amount to display
  const currentAmount = priceDetails?.finalAmount ?? price.original_price;

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const handleApplyPromo = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!promoCode.trim()) return;

    setCalculating(true);
    try {
      const response = await paymentService.applyPromoCode(price.original_price, promoCode.trim());
      if (response.kind === ResponseKind.OK && response.data) {
        setPriceDetails(response.data);
        setError(null);
      } else if(response.kind === ErrorKind.REJECTION_ERROR) {
        setError("Invalid promo code");
      }
    } catch (err) {
      console.error("Error calculating price:", err);
      setError("Error applying promo code");
    } finally {
      setCalculating(false);
    }
  };

  const handleRemovePromo = () => {
    setPromoCode('');
    setPriceDetails(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Payment system not initialized");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Payment form not initialized");
      setLoading(false);
      return;
    }

    try {
      const response = await paymentService.createSubscription(
        price.id, 
        priceDetails?.couponId
      );

      if (response.kind !== ResponseKind.OK || !response.data) {
        throw new Error("Failed to create checkout session");
      }

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        response.data.clientSecret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );

      if (error) {
        setError(error.message ?? "Payment failed");
        return;
      }

      if (paymentIntent) {
        onSuccess(paymentIntent);
      }
    } catch (err) {
      console.error("Error during payment processing:", err);
      setError("Payment processing failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="max-w-lg mx-auto p-6">

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
          {error}
        </div>
      )}
      
      <LoadingOverlay visible={pageLoading} />

      <div className="mb-6 p-6 bg-white border border-gray-200 rounded-lg shadow-sm">
        {/* Product Details Section */}
        <div className="mb-6 pb-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">{price.product.name}</h2>
          <p className="text-gray-600">{price.product.description}</p>
          
          {price.recurring && (
            <div className="mt-2 text-sm text-gray-500">
              Billed {price.recurring.interval + "ly"}
            </div>
          )}
        </div>

        {/* Price Summary Section */}
        <div className="space-y-3">
          <div className="flex justify-between text-gray-600">
            <span>Original price</span>
            <span>{formatCurrency(price.original_price, price.currency)}</span>
          </div>
          
          {/* Promo Code Section */}
          <div className="py-4">
            {priceDetails ? (
              <div className="flex items-center">
                <span className="text-sm font-medium text-gray-700">Applied discount:</span>
                <span className="ml-2 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                  {promoCode}
                  <button
                    onClick={handleRemovePromo}
                    className="ml-2 inline-flex items-center p-0.5 rounded-full hover:bg-indigo-200"
                  >
                    <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </span>
              </div>
            ) : (
              <form onSubmit={handleApplyPromo} className="space-y-2">
                <label htmlFor="promo-code" className="block text-sm font-medium text-gray-700">
                  Have a promo code?
                </label>
                <div className="flex gap-2">
                  <input
                    id="promo-code"
                    type="text"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                    placeholder="Enter promo code"
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none 
                             focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                  <button
                    type="submit"
                    disabled={calculating || !promoCode.trim()}
                    className="px-4 py-2 bg-indigo-100 text-indigo-700 rounded-md hover:bg-indigo-200 
                             focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {calculating ? 'Applying...' : 'Apply'}
                  </button>
                </div>
              </form>
            )}
          </div>

          {/* Discount Display */}
          {priceDetails && priceDetails.discountAmount > 0 && (
            <div className="flex justify-between text-green-600">
              <span>Discount applied</span>
              <span>-{formatCurrency(priceDetails.discountAmount, price.currency)}</span>
            </div>
          )}
          
          {/* Total Amount */}
          <div className="flex justify-between text-xl font-bold pt-4 border-t border-gray-200">
            <span>Total</span>
            <span>{formatCurrency(currentAmount, price.currency)}</span>
          </div>
        </div>
      </div>

      {/* Payment Form Section */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="card-element" className="block text-sm font-medium text-gray-700 mb-2">
              Payment details
            </label>
            <div className="p-4 border border-gray-300 rounded-md bg-gray-50">
              <CardElement id="card-element" options={cardElementOptions} />
            </div>
          </div>

          <button 
            type="submit" 
            disabled={!stripe || loading}
            className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md font-medium
                     hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 
                     focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing payment...
              </span>
            ) : (
              `Pay ${formatCurrency(currentAmount, price.currency)}`
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export const CheckoutPage: React.FC<CheckoutFormProps> = ({ price, onSuccess }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm price={price} onSuccess={onSuccess} />
  </Elements>
);