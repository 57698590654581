  import { useAppSelector } from "src/redux/redux-store/hooks";
  import { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import useViewportHeight from "src/hooks/useViewPortHeight";
  import { BaseSidebar } from "./BaseSidebar";
  
  interface SideBarProps {
    opened: boolean;
  }
  
 const ParentRegisterChildSidebar = () => {
    const user = useAppSelector((state) => state.user)
    const trial_days = user.trial_days;
    const [paymentUrl, setPaymentUrl] = useState("");
    const navigate = useNavigate();
    // useViewportHeight()
  
    return (
      <BaseSidebar screenName="Learner Portal" >
        <></>
    </BaseSidebar>
    );
  };
  
  export default ParentRegisterChildSidebar;
  