import React, { useState, useEffect } from 'react';
import { Text, Select, Button } from "@mantine/core";
import { GroupedPrices, PriceItem } from "src/interfaces/Payments";
import { ProPlanCard } from '../Cards/ProPlanCard';

const productDisplayNames: { [key: string]: string } = {
  "Solo Learner": "Solo",
  "Dual Learners": "Duo",
  "Trio Learners": "Trio"
};

const productDisplayDescriptions: { [key: string]: string } = {
  "Solo Learner": "For 1 learner and 1 adult for personalized, one-on-one learning.",
  "Dual Learners": "For 2 learners and 1 adult for personalized learning for two.",
  "Trio Learners": "For 3 learners and 1 adult for personalised learning for three."
};

interface DetailedProOptionsProps {
  prices: GroupedPrices | null;
  onSubscribe: (priceId: string) => void;
  onCancel: (plan: number) => void;
  hasActiveSubscription?: boolean;
  currentPlan: PriceItem | null;
  preference: "Solo Learner" | "Dual Learners" | "Trio Learners";
}

export const DetailedProOptions: React.FC<DetailedProOptionsProps> = (props) => {
  const { prices, onSubscribe, onCancel, currentPlan, preference, hasActiveSubscription } = props;
  const [selectedPackage, setSelectedPackage] = useState<string | null>(preference);
  const [selectedPrice, setSelectedPrice] = useState<string>('')

  useEffect(() => {
    if (currentPlan) {
      setSelectedPackage(currentPlan.product.name);
      setSelectedPrice(currentPlan.id)
    }
  }, [currentPlan]);

  useEffect(() => {
    if(prices && selectedPackage && prices[selectedPackage].at(0)?.id){
      let price = prices[selectedPackage].at(0)?.id ?? ''
      setSelectedPrice(price)
    }
  }, [selectedPackage, prices])

  const getIntervalLabel = (interval: string) => {
    switch (interval) {
      case 'year': return '12 Months';
      case 'month': return '1 Month';
      default: return `${interval}`;
    }
  };

  const getPlanNum = (plan: string | null) => {
    switch (plan){
      case 'Solo Learner': return 1;
      case 'Dual Learners': return 2;
      case 'Trio Learners': return 3;
      default: return 0
    }
  }

  const handleUpgrade = (plan: string | null) => {
    const planNum = getPlanNum(plan)
    onCancel(planNum)
  }


  const packageOptions = prices
    ? Object.keys(prices).map(productName => ({
      value: productName,
      label: productDisplayNames[productName] || productName
    }))
    : [];

  return (
    <div className="mb-4 w-full lg:w-3/4 xl:w-1/2 ">
      <Text size="xl" weight={500} className="mb-4">Choose Your Pro Plan</Text>
      {prices && (
        <>
          <div className='flex flex-col gap-2'>

            <Select
              classNames={{

                input: 'border-primary focus:border-primary',

              }}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: '#29748F',
                      color: 'white',
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
              })}
              placeholder="Choose a package"
              data={packageOptions}
              value={selectedPackage}
              onChange={setSelectedPackage}
              className="mb-4"
            />
            <Text className='text-sm'>{productDisplayDescriptions[selectedPackage ? selectedPackage : '']}</Text>
          </div>

          <div className="grid grid-cols-1 gap-4 my-4 mb-8">

            {selectedPackage && prices[selectedPackage].map((price) => (
              <ProPlanCard
                interval={price.recurring.interval}
                key={price.id}
                id={price.id}
                title={getIntervalLabel(price.recurring.interval)}
                price={price.original_price}
                discounted_price={price.promo_price}
                description={price.product.description}
                selectedPlan={selectedPrice}
                onSelect={(priceId: string) => setSelectedPrice(priceId)}
                isCurrentPlan={currentPlan?.lookup_key === price.lookup_key}
              />
            ))}
          </div>
          <Button
              className={'bg-primary'}
              fullWidth
              size='sm'
              radius='md'
              onClick={hasActiveSubscription ? () => handleUpgrade(selectedPackage) : () => onSubscribe(selectedPrice)}
            >
              {hasActiveSubscription ? "Manage Subscription" : "Subscribe"}
            </Button>
        </>
      )}
    </div>
  );
};