import Config from "src/config";
import { api, Api } from "../MainService/api";
import { ErrorKind, GeneralApiProblem } from "../MainService/apiProblem";
import { UserBase, UserToken } from "src/interfaces";
import {
  AzureUpload,
  DeleteFile,
  DownloadDoc,
  FileList,
  FileValidate,
  UploadFile,
} from "./documents.api.types";
import { DocumentContainerType } from "src/enums/Documents";
import { BlockBlobClient } from "@azure/storage-blob";
import { ResponseKind } from "../MainService";

const DOCUMENTS_URL = Config.DOCUMENTS_URL;
const ADMIN_URL = Config.ADMIN_URL;

export class DocumentsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAzureStorageUrl(name: string) {
    try {
      const response = await this.api.apisauce.get<{
        upload_url: string;
      }>(`${DOCUMENTS_URL}/get-azure-storage-upload-url/${name}`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }


  async uploadDoc(file: File, sessionId: string,){
    try {
      let azure_token_url = '';
      const token = await this.getAzureStorageUrl(file.name)

      if (token.kind == ResponseKind.OK) {
        if (token.data?.upload_url) azure_token_url = token.data.upload_url
      } else {
        console.log("Error encountered getting Azure URL")
        return
      }

      const config = {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": file.type,
          "x-ms-meta-chat_session_id": `${sessionId}`
        },
      };
      const response = await this.api.apisauce.put(
        azure_token_url,
        file,
        config
      );

      if (!response.ok) {
        return false
      }else{
        return true
      }

    } catch (e) {
      console.log("File uploading failed");
      Promise.reject("Bad api call");
    }
  }

  async getUserToken() {
    try {
      const response = await this.api.apisauce.get<{
        azure_storage_token: string;
      }>(`${DOCUMENTS_URL}/get-azure-storage-token`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async uploadGdrive(gDriveLink: string) {
    try {
      const response = await this.api.apisauce.post<UploadFile>(
        `${DOCUMENTS_URL}/upload-gdrive?gdrivelink=${gDriveLink}`
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async validateFiles(fileNames: string[]) {
    try {
      const response = await this.api.apisauce.post<{ files: FileValidate[] }>(
        `${DOCUMENTS_URL}/validate-documents`,
        {
          file_names: fileNames,
        }
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async downloadDoc(id: string) {
    try {
      const response = await this.api.apisauce.get<DownloadDoc>(
        `${DOCUMENTS_URL}/download/${id}`
      );

      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async deleteDocs(files: string[]) {
    try {
      const response = await this.api.apisauce.post<DeleteFile>(
        `${DOCUMENTS_URL}/delete-multiple`,
        {
          file_names: files,
        }
      );
      let data = await this.api.getResponse(response);

      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }

  async getDocuments() {
    try {
      const response = await this.api.apisauce.get<FileList>(
        `${DOCUMENTS_URL}/list`
      );
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }
}
export const documentService = new DocumentsApi(api);
