import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./screens/auth/Signin";
import Signup from "./screens/auth/Signup";
import VerifyEmail from "./screens/auth/VerifyEmail";
import Dashboard from "./screens/user/Dashboard";
import ProtectedRoute from "./navigators/wrapper/ProtectedRoute";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/redux-store/configureStore";
import { AuthProvider } from "./hooks/useAuth";
import { userRoutes } from "./navigators/routes/user";
import { authRoutes } from "./navigators/routes/auth";
import {
  AppShell,
  ButtonStylesParams,
  ColorScheme,
  ColorSchemeProvider,
  GlobalStyles,
  MantineProvider,
  Notification,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import Sidebar from "./components/SideBar";
import TopNavbar from "./components/TopNavbar";
import { useMediaQuery } from "@mantine/hooks";
import { useEffect } from "react";
import theme from "@material-tailwind/react/theme";
import { LogoutListener } from "./navigators/wrapper/LogoutListener"; // Adjust the import path as needed
import { Notifications } from "@mantine/notifications";
import { NotificationsProvider } from "./hooks/useNotifications";
import { ModalsProvider } from "@mantine/modals";
import { PersistGate } from "redux-persist/integration/react";
import ParentRoutes from "./navigators/routes/ParentRoutes";
import ForgotPassword from "./screens/auth/ForgotPassword";
import { UserRole } from "./enums/User";
import LandingPage from "./screens/landing/LandingPage";

function App() {
  const theme = useMantineTheme();
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const matches = useMediaQuery("(max-width: 768px)");
  const [opened, setOpened] = useState(false);

  const handleOpen = () => {
    setOpened((prev) => !prev);
  };

  const closeSideBar = () => {
    setOpened(false)
  }
 

  function useShowHeader() {
    const location = useLocation(); // from react-router-dom
    return !location.pathname.startsWith("/auth") && !location.pathname.includes('/payment') && !location.pathname.includes('/landing');
  }

  // Inside your AppShell component
  const showHeader = useShowHeader();
  const user = location.pathname.startsWith("/admin") ? "admin" : location.pathname.startsWith("/parent") ? "parent" : "student";
  // Your existing setup
  return (
    // Existing Provider and MantineProvider setup
    <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
         <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme: colorScheme,
              fontFamily: 'Nunito Sans, sans-serif',
              fontFamilyMonospace: 'Liberation Mono, Courier, monospace',
              headings: { fontFamily: 'Nunito Sans, sans-serif' },    
              components: {
                Text: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      color:
                        theme.colorScheme == "dark" ? theme.white : theme.black,
                        fontWeight: 400
                    },
                    
                  }),
                },
                TextInput: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      backgroundColor:
                        theme.colorScheme == "dark" ? theme.black : theme.white,
                      
                    },
                  }),
                },
              },
            }}
          >
          <ModalsProvider >
          <NotificationsProvider url="">
            <AppShell
              padding={0}
              navbarOffsetBreakpoint={768}
              layout={matches ? "default" : "alt"}
              navbar={showHeader ? user == "student" ? <Sidebar opened={opened} closeSidebar= {closeSideBar} /> : <></> : <></>}
              header=
              {
                showHeader ? (
                  user == "student" ? (
                    <TopNavbar opened={opened} handleOpen={handleOpen} />
                  ) : <></>
                ) : <></>
              }
            >
            
              <LogoutListener />
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to={userRoutes.DASHBOARD} />}
                  />
                  <Route path={authRoutes.LOGIN} element={<Login />} />
                  <Route path={authRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
                  <Route path={authRoutes.SIGNUP} element={<Signup />} />
                  <Route      
                    path={authRoutes.VERIFY_ACCOUNT}
                    element={<VerifyEmail />}
                  />
                  <Route
                    path={userRoutes.DASHBOARD}
                    element={
                      <ProtectedRoute allowedRoles={[UserRole.user]}>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route 
                    path="/parent/*"
                    element={
                        <ProtectedRoute allowedRoles={[UserRole.parent]}>
                          <ParentRoutes />
                        </ProtectedRoute>
                      }
                  />
                </Routes>
            </AppShell>
            </NotificationsProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </AuthProvider>
      </PersistGate>
    </Provider>
    // Closing tags for Provider and MantineProvider
  );
}

export default App;
